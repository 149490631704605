export enum AmericanStates {
  'AL' = 'Alabama',
  'AK' = 'Alaska',
  'AS' = 'Samoa Americana',
  'AZ' = 'Arizona',
  'AR' = 'Arkansas',
  'CA' = 'California',
  'CO' = 'Colorado',
  'CT' = 'Connecticut',
  'DE' = 'Delaware',
  'DC' = 'Washington, D. C.',
  'FM' = 'Estados Federados de Micronesia',
  'FL' = 'Florida',
  'GA' = 'Georgia',
  'HI' = 'Hawái',
  'ID' = 'Idaho',
  'IL' = 'Illinois',
  'IN' = 'Indiana',
  'IA' = 'Iowa',
  'KS' = 'Kansas',
  'KY' = 'Kentucky',
  'LA' = 'Luisiana',
  'ME' = 'Maine',
  'MH' = 'Islas Marshall',
  'MD' = 'Maryland',
  'MA' = 'Massachusetts',
  'MI' = 'Míchigan',
  'MN' = 'Minnesota',
  'MS' = 'Misisipi',
  'MO' = 'Misuri',
  'MT' = 'Montana',
  'NE' = 'Nebraska',
  'NV' = 'Nevada',
  'NH' = 'Nuevo Hampshire',
  'NJ' = 'Nueva Jersey',
  'NM' = 'Nuevo México',
  'NY' = 'Nueva York',
  'NC' = 'Carolina del Norte',
  'ND' = 'Dakota del Norte',
  'MP' = 'Islas Marianas del Norte',
  'OH' = 'Ohio',
  'OK' = 'Oklahoma',
  'OR' = 'Oregón',
  'PA' = 'Pensilvania',
  'RI' = 'Rhode Island',
  'SC' = 'Carolina del Sur',
  'SD' = 'Dakota del Sur',
  'TN' = 'Tennessee',
  'TX' = 'Texas',
  'UT' = 'Utah',
  'VT' = 'Vermont',
  'VI' = 'Islas Vírgenes de Estados Unidos',
  'VA' = 'Virginia',
  'WA' = 'Washington',
  'WV' = 'Virginia Occidental',
  'WI' = 'Wisconsin',
  'WY' = 'Wyoming',
  'AA' = 'Las Fuerzas Armadas de los Estados Unidos',
  'AP' = 'Fuerza Aérea Naval del Pacífico',
  'AE' = 'Fuerzas Armadas de la Unión Europea',
}
