import { Component, OnDestroy, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../ngrx';
import { selectRouteData } from '../../ngrx/router/router.actions';
import { AbstractComponent } from '../abstract.component';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  public selectedPath!: string;

  constructor(
    protected override _store: Store<AppState>,
    protected override _adapter: DateAdapter<any>,
    protected override _translateService: TranslateService,
    protected override _loggerService: LoggerService,
  ) {
    super(_store, _adapter, _translateService, _loggerService);
  }

  // -------------------------------
  // - LIFE CYCLE HOOKS
  // -------------------------------

  override ngOnInit(): void {
    super.ngOnInit();

    // Update the selectedPath when it's changing
    this._store
      .pipe(select(selectRouteData), takeUntil(this._unsubscriber))
      .subscribe((data: Data) => (this.selectedPath = data['path']));
  }

  // -------------------------------
  // - PUBLICS METHODS
  // -------------------------------

  onScrollToTop(event: any): void {
    document.querySelector('#scroll-anchor')?.scrollTo(0, 0);
  }
}
