import { ErrorCode } from '../enums/error-code.enum';

export class HcAirlinesError extends Error {
  status?: string;
  code?: string;

  private static readonly DEFAULT_HC_AIRLINES_ERROR_CODE = 'HC_AIRLINES_ERROR';

  constructor(message: string, code?: string, status?: string) {
    super(message);
    this.status = status;
    this.message = message;
    this.code = code;
  }

  public static buildDefault() {
    return new HcAirlinesError('An error occurred', ErrorCode.RF009);
  }

  public extractCode() {
    return this.code ?? HcAirlinesError.DEFAULT_HC_AIRLINES_ERROR_CODE;
  }

  public override toString() {
    return this.extractCode() + ' : ' + Error.prototype.toString.call(this);
  }
}
