import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HWTransferMethod, HWWidgets } from '../apis/hyperwallet/types';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { AppState } from '../ngrx';
import { LoggerService } from './logger.service';
import { getCurrentLang } from '../ngrx/global/global.selectors';

export enum HyperWalletState {
  Idle,
  Loading,
  Loaded,
  Processing,
  Processed,
  Error,
}

@Injectable()
export class HyperwalletService implements OnDestroy {
  private unsubcriber = new Subject();
  private hyperwallet: Subject<HWWidgets>;
  private currentLang = 'en';

  public loadState: Subject<HyperWalletState> = new Subject();
  public hyperwallet$: Observable<HWWidgets> = new Observable();

  constructor(
    private _store: Store<AppState>,
    private _loggerService: LoggerService,
  ) {
    this.hyperwallet = new Subject<HWWidgets>();
    this.hyperwallet$ = this.hyperwallet.asObservable();
    this.loadState.next(HyperWalletState.Idle);
    this._store
      .pipe(select(getCurrentLang), takeUntil(this.unsubcriber))
      .subscribe((lang) => (this.currentLang = lang));
  }

  injectScript(userId: string) {
    this.loadState.next(HyperWalletState.Loading);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `${environment.hyperwalletUrl}${userId}/${this.currentLang}.v2_4_5.min.js`;
    script.onerror = (error) => {
      this._loggerService.error(`Hyperwallet script load error: ${error}`);
      this.loadState.next(HyperWalletState.Error);
    };
    script.onload = () => {
      this.hyperwallet.next(window.HWWidgets);
      this.loadState.next(HyperWalletState.Loaded);
    };
    document.head.appendChild(script);
  }

  listen(hyperwallet: HWWidgets, token: string, currency: string): void {
    hyperwallet.initialize((onSuccess) => {
      onSuccess(token);
    });

    hyperwallet.transferMethods
      .configure({
        el: document.getElementById('TransferMethodUI'),
      })
      .create({
        profileType: 'INDIVIDUAL',
        currency,
      });

    hyperwallet.events.on(
      'widget:transfermethods:completed',
      (trmObject: HWTransferMethod) => {
        if (trmObject) {
          window.dispatchEvent(
            new CustomEvent('hopper-hyperwallet', {
              detail: {
                trmObject: trmObject,
              },
            }),
          );
        }
      },
    );

    hyperwallet.events.on('widget:loading:shown', () =>
      this.loadState.next(HyperWalletState.Processing),
    );
    hyperwallet.events.on('widget:loading:hidden', () =>
      this.loadState.next(HyperWalletState.Processed),
    );

    hyperwallet.events.on('widget:error:shown', (error: string) => {
      this._loggerService.error(`hyperwallet error: ${error}`);
    });
  }

  ngOnDestroy(): void {
    this.unsubcriber.next(null);
    this.unsubcriber.complete();
  }
}
