import { Injectable, OnDestroy } from '@angular/core';
import { HandlerType, datadogLogs as datadog } from '@datadog/browser-logs';
import { environment } from '../../../environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from '../ngrx';
import { Store, createSelector, select } from '@ngrx/store';
import {
  getContractId,
  getPartnerId,
  getPartnerName,
  getSessionId,
} from '../ngrx/home/home.selectors';
import { getCurrentLang } from '../ngrx/global/global.selectors';

type HandlerArguments = Parameters<typeof datadog.logger.error>;

const env = environment.production
  ? 'production'
  : environment.staging
    ? 'staging'
    : environment.development
      ? 'development'
      : 'local';

// Add tag prefix in production - https://hopper-jira.atlassian.net/browse/HTSFA-1051
const version = environment.production
  ? `v${environment.version}`
  : environment.version;

const contextSelector = createSelector(
  getCurrentLang,
  getSessionId,
  getContractId,
  getPartnerId,
  getPartnerName,
  (lang, session_id, product_id, partner_id, partner_name) => ({
    lang,
    session_id,
    product_id,
    partner_id,
    partner_name,
    env,
  }),
);

@Injectable()
export class LoggerService implements OnDestroy {
  private _globalContext: any = {};
  private _unsubscriber = new Subject();
  private _service = 'hts-airlines-dg-claims-app';
  private _site = 'us5.datadoghq.com';

  private _initConfig = {
    clientToken: 'pubc1227a869b47ba8b221db32fc8caacfc',
    site: this._site,
    service: this._service,
    env,
    version: environment.version,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    silentMultipleInit: true,
  };
  private _rumInitConfig = {
    applicationId: '0441ce72-733c-4732-a715-9f47df10fbef',
    clientToken: 'pub26d4728794bf3771aed93ff0278ccdab',
    site: this._site,
    service: this._service,
    env,
    version,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    allowedTracingUrls: [],
  };

  constructor(private _store: Store<AppState>) {
    datadog.init(this._initConfig);
    datadog.logger.setHandler(this._getHandlerType(env));

    this._globalContext = {
      data: {
        jsonPayload: {
          env,
        },
      },
    };

    datadog.setGlobalContext(this._globalContext);

    this._startRumSession(this._globalContext);

    this._store
      .pipe(select(contextSelector), takeUntil(this._unsubscriber))
      .subscribe((context) => {
        this._addContextInJsonPayload(context);
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next(null);
    this._unsubscriber.complete();
  }

  // ----------------------------------------------------------
  // PUBLICS METHODS
  // ----------------------------------------------------------
  public info(...args: HandlerArguments): void {
    datadog.logger.info.apply(datadog.logger, args);
  }

  public warn(...args: HandlerArguments): void {
    datadog.logger.warn.apply(datadog.logger, args);
  }

  public error(...args: HandlerArguments): void {
    datadog.logger.error.apply(datadog.logger, args);
  }

  // ----------------------------------------------------------
  // PRIVATES METHODS
  // ----------------------------------------------------------
  private _addContextInJsonPayload(payload: Record<string, any>): void {
    Object.entries(payload).forEach(([key, value]) => {
      this._globalContext.data.jsonPayload[key] = value;
    });

    datadog.setGlobalContext(this._globalContext);
    this._datadogRUM().then((rum) => rum.setGlobalContext(this._globalContext));
  }

  private _getHandlerType(env: string): HandlerType | HandlerType[] {
    switch (env) {
      case 'production':
        return HandlerType.http;
      case 'staging':
        return [HandlerType.http, HandlerType.console];
      default:
        return HandlerType.console;
    }
  }

  private async _datadogRUM() {
    const { datadogRum } = await import('@datadog/browser-rum');
    return datadogRum;
  }

  private async _startRumSession(context: Record<string, any>): Promise<void> {
    const datadogRum = await this._datadogRUM();
    datadogRum.setGlobalContext(context);
    datadogRum.init(this._rumInitConfig);
    datadogRum.startSessionReplayRecording();
  }
}
