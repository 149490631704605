// http://www.loc.gov/standards/iso639-2/php/code_list.php

import * as English from './locales/en/common.json';
import * as French from './locales/fr/common.json';
import * as Spanish from './locales/es/common.json';

export type Locale = 'en' | 'fr' | 'es';

// Availables Languages
export const Locales = new Map<Locale, typeof English>([
  ['en', English],
  ['fr', French],
  ['es', Spanish],
]);
