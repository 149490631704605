import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../ngrx';
import { Store, select } from '@ngrx/store';
import { getCurrentLang } from '../ngrx/global/global.selectors';
import { Subject, take, takeUntil } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { CountryCode } from '../enums/country-code.enum';
import { AmericanStates } from '../enums/american-states.enum';

@Injectable()
export class LocationI18nService implements OnDestroy {
  public mapCountries = new Map<string, string>();
  public mapStates = new Map<string, string>();

  private _unsubscriber = new Subject();

  constructor(
    private _store: Store<AppState>,
    private _translateService: TranslateService,
  ) {
    this._store
      .pipe(select(getCurrentLang), takeUntil(this._unsubscriber))
      .subscribe((currentLang: string) =>
        this._updateTranslations(currentLang),
      );
    Object.entries(AmericanStates).forEach(([code, name]) =>
      this.mapStates.set(code, name),
    );
  }

  // -----------------------------------------------
  // Life Cycle Hooks
  // -----------------------------------------------

  ngOnDestroy(): void {
    this._unsubscriber.next(null);
    this._unsubscriber.complete();
  }

  // -----------------------------------------------
  // Publics
  // -----------------------------------------------

  public getLabelFromErrorKey(
    labelKey: string,
    errorCode: string,
    contactFormUrl: string,
  ): string {
    let result = '';

    this._translateService
      .get('COMMON.ERROR_CODE.' + labelKey, { errorCode, link: contactFormUrl })
      .pipe(take(1))
      .subscribe((label) => (result = label));

    return result;
  }

  // -----------------------------------------------
  // Privates
  // -----------------------------------------------

  private _updateTranslations(currentLang: string): void {
    const countries = Object.keys(CountryCode);

    const label = new Intl.DisplayNames([currentLang], { type: 'region' });

    countries.forEach((countryCode) => {
      this.mapCountries.set(countryCode, label.of(countryCode) || '');
    });
  }
}
