import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/shared/ngrx';
import {
  getCurrentCulturalLang,
  getCurrentLang,
  getCurrentTheme,
} from 'src/app/shared/ngrx/global/global.selectors';
import { Locales } from '../i18n';
import { MatStepper } from '@angular/material/stepper';
import { LoggerService } from '../services/logger.service';
import { type Locale } from '../i18n';
import { type Locale as DateFnsLocale } from 'date-fns';
import {
  setCurrentCulturalLang,
  setCurrentLang,
} from '../ngrx/global/global.actions';
import { registerLocaleData } from '@angular/common';

const dateFnsLocales: Record<
  Locale,
  () => Promise<{ default: DateFnsLocale }>
> = {
  es: () => import(`@/../node_modules/date-fns/locale/es/index.js`),
  en: () => import(`@/../node_modules/date-fns/locale/en-US/index.js`),
  fr: () => import(`@/../node_modules/date-fns/locale/fr/index.js`),
};

const angularCommonLocales: Record<Locale, Promise<{ default: any }>> = {
  en: import('@/../node_modules/@angular/common/locales/en'),
  fr: import('@/../node_modules/@angular/common/locales/fr'),
  es: import('@/../node_modules/@angular/common/locales/es'),
};

@Directive({
  selector: '[AbstractComponent]',
})
export abstract class AbstractComponent implements OnInit, OnDestroy {
  protected _unsubscriber = new Subject();
  protected _fakeTimer: number = 1000;
  protected _errorTimer: number = 1000;

  public currentTheme!: string;
  public currentLang!: string;
  public currentCulturalLang!: string;

  constructor(
    protected _store: Store<AppState>,
    protected _adapter: DateAdapter<any>,
    protected _translateService: TranslateService,
    protected _loggerService: LoggerService,
  ) {
    // Set Labels
    Locales.forEach((data: Object, lang: string) => {
      this._translateService.setTranslation(lang, data, true);
    });

    // Set availables languages
    this._translateService.addLangs(Array.from(Locales.keys()));

    // Set default language
    const defaultLang = this._translateService.getBrowserLang() || 'en';

    // Set the current lang in the Store
    this._store.dispatch(setCurrentLang({ currentLang: defaultLang }));
  }

  // -----------------------------------------------
  // Life Cycle Hooks
  // -----------------------------------------------

  ngOnInit(): void {
    // Update the current theme when it's changing
    this._store
      .pipe(select(getCurrentTheme), takeUntil(this._unsubscriber))
      .subscribe((currentTheme: string) => {
        if (this.currentTheme) {
          // Remove active theme
          document.body.classList.remove(this.currentTheme);
        }

        // Activate the new theme
        this.currentTheme = currentTheme;
        document.body.classList.add(this.currentTheme);
      });

    // update the currentLang when it's changing
    this._store
      .pipe(select(getCurrentLang), takeUntil(this._unsubscriber))
      .subscribe((currentLanguage: string) => {
        this.currentLang = currentLanguage;
        this._store.dispatch(
          setCurrentCulturalLang({
            currentCulturalLang: this._getCulturalLangFromLang(
              this.currentLang,
            ),
          }),
        );

        // Set language in differents sources
        this._loadAngularCommonLocale(currentLanguage);
        this._loadDateFnsLocale(currentLanguage);

        this._translateService.use(currentLanguage);
      });

    this._store
      .pipe(select(getCurrentCulturalLang), takeUntil(this._unsubscriber))
      .subscribe(
        (currentCulturalLang: string) =>
          (this.currentCulturalLang = currentCulturalLang),
      );
  }

  ngOnDestroy(): void {
    this._unsubscriber.next(null);
    this._unsubscriber.complete();
  }

  // -----------------------------------------------
  // Publics methods
  // -----------------------------------------------

  public scrollToTop(anchor: ElementRef, timer?: number): void {
    // Scroll to top of component (timer due to rendering delay)
    setTimeout(() => anchor.nativeElement.scrollTo(0, 0), timer || 0);
  }

  // -----------------------------------------------
  // Protected methods
  // -----------------------------------------------

  protected _validCurrentStep(stepper: MatStepper): void {
    if (stepper) {
      if (stepper.selected) {
        // complete the current step
        stepper.selected.completed = true;
      }

      // move to next step
      stepper.next();
    }
  }

  // -----------------------------------------------
  // Privates methods
  // -----------------------------------------------

  private _loadDateFnsLocale = (locale: string) => {
    const targetModule =
      locale in dateFnsLocales
        ? dateFnsLocales[locale as unknown as Locale]
        : dateFnsLocales['en'];

    targetModule().then((module) => {
      this._adapter.setLocale(module.default);
    });
  };

  private _loadAngularCommonLocale = (locale: string) => {
    const targetModule =
      locale in angularCommonLocales
        ? angularCommonLocales[locale as unknown as Locale]
        : angularCommonLocales['en'];
    targetModule.then((module) => registerLocaleData(module.default));
  };

  private _getCulturalLangFromLang(lang: string): string {
    let result = 'en_US';

    switch (lang) {
      case 'fr':
        result = 'fr_CA';
        break;
      case 'es':
        result = 'es_MX';
        break;
    }

    return result;
  }
}
