import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import {
  CancelForAnyReasonCFARApi,
  CheckDgContractVerificationCodeRequest,
  CompleteDgExerciseRequest,
  Configuration,
  CreateDgRefundAuthorizationRequest,
  CreateDgRefundRecipientRequest,
  DgProcessRebookingCustomerRequest,
  DgSearchAlternativesCustomerRequest,
  DisruptionGuaranteeDGApi,
  SendDgContractVerificationCodeRequest,
} from '@hts/partner-airlines-api-client';
import { environment } from 'src/environments/environment';

@Injectable()
export class HopperAirlinesService {
  // Private
  private _dgClient(hCSessionId: string) {
    const configuration = new Configuration({
      basePath: environment.apiUrl,
      apiKey: hCSessionId,
    });
    return new DisruptionGuaranteeDGApi(configuration);
  }

  private _cfarClient(hCSessionId: string) {
    const configuration = new Configuration({
      basePath: environment.apiUrl,
      apiKey: hCSessionId,
    });
    return new CancelForAnyReasonCFARApi(configuration);
  }

  // Public
  getRetrieveCustomerSession(hCSessionId: string) {
    const client = this._cfarClient(hCSessionId);
    return from(client.getCustomerSessionsRetrieve());
  }

  postCustomerDgSendVerificationCode(
    sendDgContractVerificationCodeRequest: SendDgContractVerificationCodeRequest,
  ) {
    const client = this._dgClient('');
    return from(
      client.postCustomerDgSendVerificationCode({
        sendDgContractVerificationCodeRequest,
      }),
    );
  }

  postCustomerDgCheckVerificationCode(
    checkDgContractVerificationCodeRequest: CheckDgContractVerificationCodeRequest,
  ) {
    const client = this._dgClient('');
    return from(
      client.postCustomerDgCheckVerificationCode({
        checkDgContractVerificationCodeRequest,
      }),
    );
  }

  postCustomerDgAlternatives(
    dgSearchAlternativesCustomerRequest: DgSearchAlternativesCustomerRequest,
    hCSessionId: string,
    verificationCode: string,
  ) {
    const client = this._dgClient(hCSessionId);
    return from(
      client.postCustomerDgAlternatives({
        dgSearchAlternativesCustomerRequest,
        verificationCode,
      }),
    );
  }

  postCustomerDgRebook(
    dgProcessRebookingCustomerRequest: DgProcessRebookingCustomerRequest,
    hCSessionId: string,
    verificationCode: string,
  ) {
    const client = this._dgClient(hCSessionId);
    return from(
      client.postCustomerDgRebook({
        dgProcessRebookingCustomerRequest,
        verificationCode,
      }),
    );
  }

  postCustomerDgRefundRecipients(
    createDgRefundRecipientRequest: CreateDgRefundRecipientRequest,
    hCSessionId: string,
    verificationCode: string,
  ) {
    const client = this._dgClient(hCSessionId);
    return from(
      client.postCustomerDgRefundRecipients({
        createDgRefundRecipientRequest,
        verificationCode,
      }),
    );
  }

  postCustomerDgRefundAuthorizations(
    createDgRefundAuthorizationRequest: CreateDgRefundAuthorizationRequest,
    hCSessionId: string,
    verificationCode: string,
  ) {
    const client = this._dgClient(hCSessionId);
    return from(
      client.postCustomerDgRefundAuthorizations({
        createDgRefundAuthorizationRequest,
        verificationCode,
      }),
    );
  }

  postCustomerDgMarkCompleted(
    completeDgExerciseRequest: CompleteDgExerciseRequest,
    hCSessionId: string,
    verificationCode: string,
  ) {
    const client = this._dgClient(hCSessionId);
    return from(
      client.postCustomerDgMarkCompleted({
        completeDgExerciseRequest,
        verificationCode,
      }),
    );
  }
}
