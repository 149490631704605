<mat-toolbar class="fx-row fx-r-start-center">
  <div class="fx-r-90 fx-row fx-r-center-center">
    <img class="logo" src="assets/logo.png" />
  </div>

  <div class="fx-r-10 fx-row fx-r-end-center">
    <button mat-button [matMenuTriggerFor]="languageMenu">
      <span>{{ currentLang | uppercase }}</span>
    </button>

    <mat-menu #languageMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let language of languages"
        (click)="onSetLanguage(language)"
      >
        <span>{{ language | uppercase }}</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
