import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NavBarComponent } from './components/navbar/navbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { FlightInformationsComponent } from './components/flight-informations/flight-informations.component';
import { MatCardModule } from '@angular/material/card';
import { AlternativeInformationsComponent } from './components/alternative-informations/alternative-informations.component';
import { PassengersInformationsComponent } from './components/passengers-informations/passengers-informations.component';
import { HyperwalletService } from './services/hyperwallet.service';
import { LocationI18nService } from './services/location-i18n.service';

@NgModule({
  declarations: [
    NavBarComponent,
    LayoutComponent,
    LoaderComponent,
    AlternativeInformationsComponent,
    FlightInformationsComponent,
    PassengersInformationsComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,
    RouterModule,
    HttpClientModule,
    TranslateModule.forChild(),

    // Angular Material Modules
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatTabsModule,
    MatCardModule,
  ],
  exports: [
    NavBarComponent,
    LayoutComponent,
    LoaderComponent,
    AlternativeInformationsComponent,
    FlightInformationsComponent,
    PassengersInformationsComponent,
  ],
  providers: [HyperwalletService, LocationI18nService],
})
export class SharedModule {}
