import { createAction, props } from '@ngrx/store';

export const setCurrentLang = createAction(
  '[Global] Set the current language',
  props<{ currentLang: string }>(),
);
export const setCurrentCulturalLang = createAction(
  '[Global] Set the current cultural language',
  props<{ currentCulturalLang: string }>(),
);
export const setCurrentTheme = createAction(
  '[Global] Set the current theme',
  props<{ currentTheme: string }>(),
);
