/**
 * Non exaustive list of error managed by the API
 * Excluded codes : event (EVXXX)
 */
export enum ErrorCode {
  AL001 = 'AL001',
  AL002 = 'AL002',
  AL003 = 'AL003',
  AL004 = 'AL004',

  CO053 = 'CO053',
  CO054 = 'CO054',
  CO055 = 'CO055',
  CO056 = 'CO056',
  CO057 = 'CO057',
  CO058 = 'CO058',
  CO059 = 'CO059',
  CO060 = 'CO060',
  CO061 = 'CO061',
  CO062 = 'CO062',
  CO063 = 'CO063',
  CO064 = 'CO064',
  CO065 = 'CO065',
  CO066 = 'CO066',
  CO083 = 'CO083',
  CO084 = 'CO084',
  CO085 = 'CO085',
  CO086 = 'CO086',
  CO087 = 'CO087',
  CO088 = 'CO088',
  CO089 = 'CO089',
  CO090 = 'CO090',
  CO091 = 'CO091',
  CO092 = 'CO092',
  CO093 = 'CO093',
  CO094 = 'CO094',
  CO095 = 'CO095',
  CO096 = 'CO096',
  CO097 = 'CO097',
  CO098 = 'CO098',
  CO099 = 'CO099',
  CO100 = 'CO100',
  CO101 = 'CO101',
  CO102 = 'CO102',
  CO103 = 'CO103',
  CO104 = 'CO104',
  CO105 = 'CO105',
  CO106 = 'CO106',
  CO107 = 'CO107',
  CO108 = 'CO108',
  CO109 = 'CO109',
  CO110 = 'CO110',
  CO111 = 'CO111',
  CO112 = 'CO112',
  CO114 = 'CO114',
  CO116 = 'CO116',
  CO117 = 'CO117',
  CO118 = 'CO118',
  CO119 = 'CO119',
  CO120 = 'CO120',
  CO123 = 'CO123',
  CO124 = 'CO124',
  CO126 = 'CO126',
  CO127 = 'CO127',
  CO128 = 'CO128',
  CO138 = 'CO138',
  CO141 = 'CO141',
  CO142 = 'CO142',
  CO144 = 'CO144',
  CO145 = 'CO145',
  CO147 = 'CO147',
  CO148 = 'CO148',
  CO149 = 'CO149',
  CO150 = 'CO150',
  CO151 = 'CO151',
  CO152 = 'CO152',
  CO153 = 'CO153',
  CO154 = 'CO154',
  CO155 = 'CO155',
  CO156 = 'CO156',
  CO169 = 'C0169',
  CO170 = 'CO170',
  CO171 = 'CO171',
  CO172 = 'CO172',
  CO173 = 'CO173',

  DI001 = 'DI001',
  DI002 = 'DI002',
  DI003 = 'DI003',
  DI004 = 'DI004',
  DI005 = 'DI005',
  DI006 = 'DI006',
  DI007 = 'DI007',
  DI008 = 'DI008',
  DI009 = 'DI009',
  DI010 = 'DI010',
  DI011 = 'DI011',
  DI012 = 'DI012',
  DI013 = 'DI013',
  DI014 = 'DI014',
  DI015 = 'DI015',
  DI016 = 'DI016',
  DI017 = 'DI017',
  DI018 = 'DI018',
  DI019 = 'DI019',
  DI020 = 'DI020',
  DI021 = 'DI021',
  DI022 = 'DI022',
  DI023 = 'DI023',
  DI024 = 'DI024',
  DI025 = 'DI025',
  DI026 = 'DI026',

  EM001 = 'EM001',
  EM002 = 'EM002',

  EX002 = 'EX002',
  EX003 = 'EX003',
  EX004 = 'EX004',
  EX017 = 'EX017',
  EX018 = 'EX018',
  EX036 = 'EX036',
  EX039 = 'EX039',
  EX054 = 'EX054',
  EX062 = 'EX062',
  EX067 = 'EX067',
  EX068 = 'EX068',
  EX069 = 'EX069',
  EX070 = 'EX070',
  EX071 = 'EX071',
  EX072 = 'EX072',
  EX073 = 'EX073',
  EX075 = 'EX075',
  EX076 = 'EX076',
  EX077 = 'EX077',
  EX078 = 'EX078',
  EX079 = 'EX079',
  EX080 = 'EX080',
  EX081 = 'EX081',
  EX082 = 'EX082',
  EX083 = 'EX083',
  EX084 = 'EX084',
  EX085 = 'EX085',
  EX086 = 'EX086',
  EX087 = 'EX087',
  EX088 = 'EX088',
  EX089 = 'EX089',
  EX090 = 'EX090',
  EX091 = 'EX091',
  EX092 = 'EX092',
  EX093 = 'EX093',
  EX094 = 'EX094',
  EX095 = 'EX095',
  EX096 = 'EX096',
  EX097 = 'EX097',
  EX098 = 'EX098',
  EX099 = 'EX099',
  EX100 = 'EX100',
  EX101 = 'EX101',
  EX102 = 'EX102',
  EX103 = 'EX103',
  EX104 = 'EX104',
  EX105 = 'EX105',
  EX106 = 'EX106',
  EX107 = 'EX107',
  EX108 = 'EX108',
  EX109 = 'EX109',
  EX110 = 'EX110',
  EX111 = 'EX111',
  EX112 = 'EX112',
  EX115 = 'EX115',
  EX116 = 'EX116',
  EX119 = 'EX119',
  EX120 = 'EX120',
  EX121 = 'EX121',
  EX122 = 'EX122',
  EX123 = 'EX123',
  EX126 = 'EX126',
  EX127 = 'EX127',
  EX128 = 'EX128',
  EX129 = 'EX129',
  EX130 = 'EXO38',
  EX132 = 'EX132',
  EX133 = 'EX133',
  EX134 = 'EX134',
  EX135 = 'EX135',
  EX139 = 'EX139',
  EX140 = 'EX140',
  EX141 = 'EX141',
  EX142 = 'EX142',
  EX143 = 'EX143',
  EX144 = 'EX144',
  EX146 = 'EX146',
  EX147 = 'EX147',
  EX148 = 'EX148',
  EX151 = 'EX151',
  EX152 = 'EX152',
  EX153 = 'EX153',
  EX154 = 'EX154',
  EX155 = 'EX155',
  EX156 = 'EX156',

  OF001 = 'OF001',
  OF002 = 'OF002',
  OF014 = 'OF014',
  OF015 = 'OF015',
  OF016 = 'OF016',
  OF029 = 'OF029',
  OF031 = 'OF031',
  OF032 = 'OF032',
  OF033 = 'OF033',
  OF034 = 'OF034',
  OF035 = 'OF035',
  OF036 = 'OF036',
  OF037 = 'OF037',
  OF038 = 'OF038',
  OF041 = 'OF041',
  OF042 = 'OF042',
  OF043 = 'OF043',
  OF044 = 'OF044',
  OF045 = 'OF045',
  OF046 = 'OF046',
  OF047 = 'OF047',
  OF019 = 'OF019',
  OF048 = 'OF048',
  OF049 = 'OF049',
  OF050 = 'OF050',

  SE001 = 'SE001',
  SE002 = 'SE002',
  SE003 = 'SE003',
  SE004 = 'SE004',
  SE005 = 'SE005',
  SE006 = 'SE006',
  SE007 = 'SE007',
  SE009 = 'SE009',
  SE011 = 'SE011',
  SE012 = 'SE012',
  SE014 = 'SE014',
  SE015 = 'SE015',
  SE018 = 'SE018',
  SE019 = 'SE019',
  SE020 = 'SE020',

  SG001 = 'SG001',
  SG002 = 'SG002',
  SG003 = 'SG003',
  SG004 = 'SG004',
  SG005 = 'SG005',
  SG006 = 'SG006',

  SH001 = 'SH001',
  SH002 = 'SH002',
  SH003 = 'SH003',
  SH004 = 'SH004',
  SH005 = 'SH005',
  SH006 = 'SH006',
  SH007 = 'SH007',
  SH008 = 'SH008',

  RF002 = 'RF002',
  RF003 = 'RF003',
  RF004 = 'RF004',
  RF005 = 'RF005',
  RF006 = 'RF006',
  RF007 = 'RF007',
  RF008 = 'RF008',
  RF009 = 'RF009',
  RF010 = 'RF010',
  RF011 = 'RF011',
  RF012 = 'RF012',
  RF013 = 'RF013',
  RF014 = 'RF014',
  RF015 = 'RF015',
  RF016 = 'RF016',
  RF017 = 'RF017',
  RF018 = 'RF018',
  RF019 = 'RF019',
  RF020 = 'RF020',
  RF021 = 'RF021',
  RF022 = 'RF022',
  RF023 = 'RF023',
}
