import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeState } from './home.reducer';

export const getHomeState = createFeatureSelector<HomeState>('home');
export const getIsLoading = createSelector(
  getHomeState,
  (state) => state.isLoading,
);
export const getStep = createSelector(getHomeState, (state) => state.step);
export const getErrorCode = createSelector(
  getHomeState,
  (state) => state.errorCode,
);
export const getUserEmail = createSelector(
  getHomeState,
  (state) => state.userEmail,
);
export const getIsVerificationCodeAlreadySent = createSelector(
  getHomeState,
  (state) => state.isVerificationCodeAlreadySent,
);
export const getContractId = createSelector(
  getHomeState,
  (state) => state.contractId,
);
export const getSegmentId = createSelector(
  getHomeState,
  (state) => state.segmentId,
);
export const getSessionId = createSelector(
  getHomeState,
  (state) => state.sessionId,
);
export const getVerificationCode = createSelector(
  getHomeState,
  (state) => state.verificationCode,
);
export const getCoveragePercentage = createSelector(
  getHomeState,
  (state) => state.coveragePercentage,
);
export const getExercise = createSelector(
  getHomeState,
  (state) => state.exercise,
);
export const getSelectedAlternative = createSelector(
  getHomeState,
  (state) => state.selectedAlternative,
);
export const getSelectedSlice = createSelector(
  getHomeState,
  (state) => state.selectedSlice,
);
export const getHasDisruption = createSelector(
  getHomeState,
  (state) => state.hasDisruption,
);
export const getContactFormUrl = createSelector(
  getHomeState,
  (state) => state.contactFormUrl,
);
export const getIsFlowCompleted = createSelector(
  getHomeState,
  (state) => state.isFlowCompleted,
);
export const getCurrencyCode = createSelector(
  getHomeState,
  (state) => state.currencyCode,
);
export const getPartnerId = createSelector(
  getHomeState,
  (state) => state.partnerId,
);
export const getPartnerName = createSelector(
  getHomeState,
  (state) => state.partnerName,
);
export const getMarketingCarrierCode = createSelector(
  getHomeState,
  (state) => state.marketingCarrierCode,
);
export const getServiceCap = createSelector(
  getHomeState,
  (state) => state.serviceCap,
);
export const getMinMinutesDelay = createSelector(
  getHomeState,
  (state) => state.minMinutesDelay,
);
export const getMaxHoursBeforeDeparture = createSelector(
  getHomeState,
  (state) => state.maxHoursBeforeDeparture,
);
