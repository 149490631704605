import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { DgEvent, ExerciseStepResult } from '@hts/partner-airlines-api-client';
import {
  Configuration,
  DisruptionGuaranteeDGApi,
} from '@hts/partner-airlines-api-client';
import { environment } from 'src/environments/environment';
import { handleRequestError } from '../utils';
import { LoggerService } from './logger.service';

@Injectable()
export class HopperEventsService {
  constructor(private _loggerService: LoggerService) {}

  // -----------------------------------------------
  // Private Methods
  // -----------------------------------------------
  private _dgClient(hCSessionId: string) {
    const configuration = new Configuration({
      basePath: environment.apiUrl,
      apiKey: hCSessionId,
    });
    return new DisruptionGuaranteeDGApi(configuration);
  }

  private _postCustomerDgEvent(hCSessionId: string, dgEvent: DgEvent) {
    return from(
      this._dgClient(hCSessionId)
        .postCustomerDgEvents({ dgEvent })
        .catch(async (e) => {
          const err = await handleRequestError(e);
          this._loggerService.error(
            `Failed to send event ${dgEvent.type}`,
            {},
            err,
          );
        }),
    );
  }

  // -----------------------------------------------
  // Public Methods
  // -----------------------------------------------

  // *********************
  // Exercise events
  // *********************

  postCreateDgExerciseVerificationComplete(
    hCSessionId: string,
    dgExerciseId: string,
    exerciseStepResult: ExerciseStepResult,
  ) {
    return this._postCustomerDgEvent(hCSessionId, {
      occurredDateTime: new Date(),
      dgExerciseId: dgExerciseId,
      result: exerciseStepResult,
      type: 'dg_exercise_verification_complete',
    });
  }

  postCreateDgExerciseCustomerDataComplete(
    hCSessionId: string,
    dgExerciseId: string,
    exerciseStepResult: ExerciseStepResult,
  ) {
    return this._postCustomerDgEvent(hCSessionId, {
      occurredDateTime: new Date(),
      dgExerciseId: dgExerciseId,
      result: exerciseStepResult,
      type: 'dg_exercise_customer_data_complete',
    });
  }

  postCreateDgExercisePortalComplete(
    hCSessionId: string,
    dgExerciseId: string,
    exerciseStepResult: ExerciseStepResult,
  ) {
    return this._postCustomerDgEvent(hCSessionId, {
      occurredDateTime: new Date(),
      dgExerciseId: dgExerciseId,
      result: exerciseStepResult,
      type: 'dg_exercise_portal_complete',
    });
  }

  postCreateDgExerciseCallbackLaunched(
    hCSessionId: string,
    dgExerciseId: string,
    exerciseStepResult: ExerciseStepResult,
  ) {
    return this._postCustomerDgEvent(hCSessionId, {
      occurredDateTime: new Date(),
      dgExerciseId: dgExerciseId,
      result: exerciseStepResult,
      type: 'dg_exercise_callback_launched',
    });
  }
}
