import { Component, OnInit } from '@angular/core';
import { AppState } from './shared/ngrx';

import * as globalActions from './shared/ngrx/global/global.actions';
import { environment } from 'src/environments/environment';
import { AbstractComponent } from './shared/components/abstract.component';
import { Store } from '@ngrx/store';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from './shared/services/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AbstractComponent implements OnInit {
  constructor(
    protected override _store: Store<AppState>,
    protected override _adapter: DateAdapter<any>,
    protected override _translateService: TranslateService,
    protected override _loggerService: LoggerService,
  ) {
    super(_store, _adapter, _translateService, _loggerService);
  }

  // -------------------------------
  // - LIFE CYCLE HOOKS
  // -------------------------------

  override ngOnInit(): void {
    super.ngOnInit();

    // Set Default theme
    this._store.dispatch(
      globalActions.setCurrentTheme({ currentTheme: environment.defaultTheme }),
    );
  }
}
