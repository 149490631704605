import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Theme } from '../../enums';
import { Locales } from '../../i18n';

import { AppState } from '../../ngrx';
import * as globalActions from '../../ngrx/global/global.actions';
import { LoggerService } from '../../services/logger.service';
import { AbstractComponent } from '../abstract.component';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavBarComponent extends AbstractComponent implements OnInit {
  public languages!: string[];
  public themes!: string[];
  public isProd!: boolean;

  constructor(
    protected override _store: Store<AppState>,
    protected override _adapter: DateAdapter<any>,
    protected override _translateService: TranslateService,
    protected override _loggerService: LoggerService,
  ) {
    super(_store, _adapter, _translateService, _loggerService);

    this.isProd = environment.production;
    this.languages = Array.from(Locales.keys());
    this.themes = Object.values(Theme);
  }

  // -------------------------------
  // - LIFE CYCLE HOOKS
  // -------------------------------

  override ngOnInit(): void {
    super.ngOnInit();
  }

  // -------------------------------
  // - PUBLICS METHODS
  // -------------------------------

  onSetTheme(newTheme: string): void {
    this._store.dispatch(
      globalActions.setCurrentTheme({ currentTheme: newTheme }),
    );
  }

  onSetLanguage(newLanguage: string): void {
    this._store.dispatch(
      globalActions.setCurrentLang({ currentLang: newLanguage }),
    );
  }

  isDevEnvironment(): boolean {
    return environment.local == true || environment.development == true;
  }
}
