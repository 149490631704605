import { ResponseError } from '@hts/partner-airlines-api-client';
import { HcAirlinesError } from '../models';
import { ErrorCode } from '../enums';

export async function handleRequestError(
  value: unknown,
): Promise<HcAirlinesError> {
  let message = 'unexpected request error';
  let code = ErrorCode.RF009;

  // Includes instanceof Error
  if (!(value instanceof ResponseError)) {
    message = `${message}: ${value}`;
    return new HcAirlinesError(message, code);
  }

  const { response } = value;

  const contentType = response.headers.get('content-type');
  message = `unknown failure: status ${response.status}`;

  if (contentType === 'application/json') {
    const { errors } = await response.json();
    if (Array.isArray(errors) && errors.length > 0) {
      [{ message, code }] = errors;
    }
  } else {
    const responseText = await response.text();
    if (responseText.trim().length > 0) {
      message = responseText;
    }
  }

  return new HcAirlinesError(message, code);
}
