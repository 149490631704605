import { ActionReducerMap } from '@ngrx/store';
import { globalReducer, GlobalState } from './global/global.reducer';
import { routerReducer } from '@ngrx/router-store';
import { RouterState } from '@angular/router';
import { homeReducer, HomeState } from './home/home.reducer';

export interface AppState {
  global: GlobalState;
  home: HomeState;
  router: RouterState;
}

export const reducers: ActionReducerMap<AppState> = {
  global: globalReducer,
  home: homeReducer,
  router: routerReducer,
};
