import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GlobalState } from './global.reducer';

export const getGlobalState = createFeatureSelector<GlobalState>('global');
export const getCurrentLang = createSelector(
  getGlobalState,
  (state) => state.currentLang,
);
export const getCurrentCulturalLang = createSelector(
  getGlobalState,
  (state) => state.currentCulturalLang,
);
export const getCurrentTheme = createSelector(
  getGlobalState,
  (state) => state.currentTheme,
);
